

.background{
  width: 100vw;
  height: 100vh;
  background-image: url('/1.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left:0;
  z-index: -1;
  opacity: 90%;

}